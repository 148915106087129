import React, { Component } from 'react';

import styles from './style.module.css';

import ButtonReverse from '../../../../components/Buttons/ButtonReverse';
import Input from '../../../../components/Input';

import api from '../../../../services/api';
import { API_GUARD } from '../../../../services/env';

export default class ForgotModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isLoading: false,
      email: "",
      alertMessage: {
        title: "",
        message: ""
      },
      validate: {
        email: {
          message: "",
          isInvalid: false
        },
      },
    };
    this.toggleForgotModal = this.props.toggleForgotModal.bind(this);
    this.feedback = this.props.feedback.bind(this);
  }

  componentDidMount() {
    const { isActive } = this.props;
    this.setState({ isActive });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      const { isActive } = this.props;
      this.setState({ isActive });
    }
  }

  toggleIsLoad() {
    this.setState({ isLoading: !this.state.isLoading });
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false,
        }
      }
    });
  }

  openErrorModal(message) {
    const alertMessage = {
      title: "Ops... algo deu errado!",
      message: message
    }
    this.feedback(alertMessage);
  }

  async handlerSubmit(event) {
    event.preventDefault();
    this.toggleIsLoad();
    if (!this.state.isLoading) {
      const { email } = this.state;
      try {
        const response = await api.post(`/v1/${API_GUARD}/auth/recover`, { email });
        if (response.status && response.status === 200) {
          const { message } = response.data;
          const alertMessage = {
            title: "Envio realizado com sucesso!",
            message: message
          }
          this.feedback(alertMessage);
        }
        this.toggleForgotModal();
        this.toggleIsLoad();
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              const { message } = error.response.data;
              this.openErrorModal(message);
              this.toggleForgotModal();
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              const alertMessage = {
                title: "Ops... algo deu errado!",
                message: "Não conseguimos processar a sa solicitação. Tente novamente mais tarde."
              }
              this.openErrorModal(alertMessage);
              this.toggleForgotModal();
          }
        }
        this.toggleIsLoad();
      }
    }
  }

  render() {
    const { isActive } = this.state;
    return (
      <>
        {
          isActive ?
            (
              <div className={styles.modal}>
                <form onSubmit={(event) => this.handlerSubmit(event)} className={styles.modalContainer}>
                  <div className={styles.modalHeader}>
                    <h3>Recuperação de Senha</h3>
                  </div>
                  <div className={styles.modalBody}>
                    <label className={styles.label}>Digite seu e-mail de acesso:</label>
                    <Input
                      type="mail"
                      name="email"
                      placeholder="E-mail"
                      onChange={(event) => this.handlerChangeInput({ email: event.target.value })}
                      icon="icon-envelope"
                      isInvalid={this.state.validate.email.isInvalid}
                      invalidMessage={this.state.validate.email.message}
                    />
                  </div>
                  <div className={styles.modalFooter}>
                    <ButtonReverse
                      onClick={() => { this.toggleForgotModal() }}
                      text="Fechar"
                    />
                    <div className={styles.buttonMargin}></div>
                    <ButtonReverse
                      onClick={() => { }}
                      text={this.state.isLoading ? "Enviando..." : "Enviar"}
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            )
            : null
        }
      </>
    );
  }
}
