import React, { Component } from 'react';

import styles from './style.module.css';

import InputMask from 'react-input-mask';

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "text",
      name: "",
      placeholder: "",
      icon: "",
      isInvalid: false,
      invalidMessage: "",
      mask: '',

    }
    this.onChange = this.props.onChange.bind(this);
  }

  componentDidMount() {
    const { type, name, placeholder, icon, isInvalid, invalidMessage, mask } = this.props;
    this.setState({ type, name, placeholder, icon, isInvalid, invalidMessage });
    if (mask) {
      if (mask === 'cnpj') { this.setState({ mask: "99.999.999/0001-99" }) }
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    const { isInvalid, invalidMessage } = this.props;
    if (prevProps.isInvalid !== isInvalid) {
      this.setState({ isInvalid, invalidMessage });
    }
  }

  render() {
    const { type, name, placeholder, icon, isInvalid, invalidMessage, mask } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <InputMask className={styles.input}
            type={type}
            name={name}
            placeholder={placeholder}
            mask={mask}
            onChange={(event) => this.onChange(event)}
          />
          <i className={icon + " " + styles.inputIcon}></i>
        </div>
        {
          isInvalid ? (
            <span className={styles.validationSpan}>
              * {invalidMessage}
            </span>
          ) : null
        }
      </div>
    );
  }
}
