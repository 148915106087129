import React from 'react';
import { Provider } from 'react-redux';
import store from './store';

// import logo from './assets/images/logo/flexip.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './App.css';

import Routes from './routes';

function App() {

  return (
    <div className="content">
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  );
}

export default App;
