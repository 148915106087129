import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFound from "../Errors/NotFound";

import Login from "../../pages/Auth/Login";
import Register from "../../pages/Auth/Register";
import EmailVerify from "../../pages/Auth/EmailVerify";
import PasswordRecovery from "../../pages/Auth/PasswordRecovery";

export default function Auth({ history }) {
  return (
    <>
      <Switch>
        <Route exact path="/auth" component={Login} />
        <Route exact path="/auth/cadastrar" component={Register} />
        <Route
          exact
          path="/auth/email/:verification_code"
          component={EmailVerify}
        />
        <Route
          exact
          path="/auth/senha/nova-senha/:token"
          component={PasswordRecovery}
        />

        <Route component={NotFound} />
      </Switch>
    </>
  );
}