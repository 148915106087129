import React, { Component } from 'react';

import styles from './style.module.css';

export default class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      type: "button"
    };
    this.onClick = this.props.onClick.bind(this);
  }

  componentDidMount() {
    const { text, type } = this.props;
    type ?
      this.setState({ text, type })
      : this.setState({ text })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      const { text } = this.props;
      this.setState({ text });
    }
  }
  render() {
    return (
      <button
        className={`${styles.btn} ${styles.btnDefault}`}
        onClick={() => { this.onClick() }}
        type={this.state.type}
      >
        {this.state.text}
      </button>
    );
  }
}
