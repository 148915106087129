import React, { Component } from "react";

import api from "../../../services/api";

import styles from "./style.module.css";
import Logo from "../../../assets/images/login/logo-white.svg";

import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import ButtonLoading from "../../../components/Buttons/ButtonLoading";

import { API_GUARD } from "../../../services/env";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      register: false,
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      companyName: "",
      cnpj: "",
      pabx: "",
      ddd: "",
      isLoading: false,
      isAlertOn: false,
      forgotModal: false,
      validate: {
        name: {
          message: "",
          isInvalid: false
        },
        email: {
          message: "",
          isInvalid: false
        },
        password: {
          message: "",
          isInvalid: false
        },
        companyName: {
          message: "",
          isInvalid: false
        },
        cnpj: {
          message: "",
          isInvalid: false
        },
        pabx: {
          message: "",
          isInvalid: false
        },
        ddd: {
          message: "",
          isInvalid: false
        },
      },
      alertMessage: {
        title: "",
        message: ""
      }
    };
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async handlerRegister(event) {
    event.preventDefault();
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const { name, email, password, passwordConfirm, companyName, cnpj, pabx, ddd } = this.state;
      try {
        const response = await api.post(`/v1/${API_GUARD}/auth/register`, { name, email, password, passwordConfirm, companyName, cnpj, pabx, ddd });
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
          // const { access_token } = response.data.token;

          // localStorage.setItem("access_token", access_token);
          // history.push("/admin/dashboard");
        }
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }
  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }
  openLogin(history) {
    history.push("/");
  }

  openRegister(history) {
    history.push("/auth/cadastrar");
  }

  render() {
    const { history } = this.props;
    return (
      <>
        <div className={styles.login}>
          <div className={styles.container}>
            <div className={styles.header}></div>
            <div className={styles.body}>
              <div className={styles.card}>
                <div className={styles.cardLeftSide}>
                  <img src={Logo} alt="Flex IP" className={styles.logo} />
                  <h1 className={styles.title}>PABX Flexible Admin.</h1>
                  <ul className={styles.list}>
                    <li className={styles.listItem}>
                      <i className={"icon-magic-wand " + styles.icon}></i>Custo
                      ZERO entre Filiais
                    </li>
                    <li className={styles.listItem}>
                      <i className={"icon-layers " + styles.icon}></i>Relatórios
                      Detalhados
                    </li>
                    <li className={styles.listItem}>
                      <i
                        className={"icon-arrow-right-circle " + styles.icon}
                      ></i>
                      Fácil expansão
                    </li>
                    <li className={styles.listItem}>
                      <i className={"icon-drop " + styles.icon}></i>
                      Flexibilidade
                    </li>
                  </ul>
                </div>
                <div className={styles.mobileHeader}>
                  <img src={Logo} alt="Flex IP" className={styles.logo} />
                </div>
                <div className={styles.cardRightSide}>
                  <h2 className={styles.description}>
                    <strong>CADASTRE</strong> SUA CONTA
                  </h2>
                  <form
                    onSubmit={event => this.handlerRegister(event)}
                    className={styles.form}
                  >
                    <Input
                      type="text"
                      name="name"
                      placeholder="Nome do responsável legal"
                      icon="icon-user"
                      isInvalid={this.state.validate.name.isInvalid}
                      invalidMessage={this.state.validate.name.message}
                      onChange={(event) => this.handlerChangeInput({ name: event.target.value })}
                    />
                    <Input
                      type="text"
                      name="email"
                      placeholder="E-mail"
                      icon="icon-envelope"
                      isInvalid={this.state.validate.email.isInvalid}
                      invalidMessage={this.state.validate.email.message}
                      onChange={(event) => this.handlerChangeInput({ email: event.target.value })}
                    />
                    <Input
                      type="text"
                      name="companyName"
                      placeholder="Nome da empresa"
                      icon="icon-people"
                      isInvalid={this.state.validate.companyName.isInvalid}
                      invalidMessage={this.state.validate.companyName.message}
                      onChange={(event) => this.handlerChangeInput({ companyName: event.target.value })}
                    />
                    <Input
                      type="text"
                      name="cnpj"
                      mask="cnpj"
                      placeholder="CNPJ"
                      icon="icon-wallet"
                      isInvalid={this.state.validate.cnpj.isInvalid}
                      invalidMessage={this.state.validate.cnpj.message}
                      onChange={(event) => this.handlerChangeInput({ cnpj: event.target.value })}
                    />
                    <Input
                      type="text"
                      name="pabx"
                      placeholder="Nome do PABX"
                      icon="icon-settings"
                      isInvalid={this.state.validate.pabx.isInvalid}
                      invalidMessage={this.state.validate.pabx.message}
                      onChange={(event) => this.handlerChangeInput({ pabx: event.target.value })}
                    />
                    <Input
                      type="text"
                      name="ddd"
                      placeholder="DDD"
                      icon="icon-phone"
                      isInvalid={this.state.validate.ddd.isInvalid}
                      invalidMessage={this.state.validate.ddd.message}
                      onChange={(event) => this.handlerChangeInput({ ddd: event.target.value })}
                    />
                    <Input
                      type="password"
                      name="password"
                      placeholder="Senha"
                      icon="icon-lock"
                      isInvalid={this.state.validate.password.isInvalid}
                      invalidMessage={this.state.validate.password.message}
                      onChange={(event) => this.handlerChangeInput({ password: event.target.value })}
                    />
                    <Input
                      type="password"
                      name="password-confirm"
                      placeholder="Confirmar senha"
                      icon="icon-reload"
                      isInvalid={this.state.validate.password.isInvalid}
                      invalidMessage={this.state.validate.password.message}
                      onChange={(event) => this.handlerChangeInput({ passwordConfirm: event.target.value })}
                    />
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Cadastrar"
                    />
                  </form>
                </div>
              </div>
              <div className={styles.radius}>
                <div
                  className={styles.radiusLeft}
                  onClick={() => {
                    this.openRegister(history);
                  }}
                >
                  <p className={styles.radiusItem}>Cadastrar sua empresa</p>
                </div>
                <div
                  className={styles.radiusRight}
                  onClick={() => {
                    this.openLogin(history);
                  }}
                >
                  <p className={styles.radiusItem}>Fazer login</p>
                </div>
              </div>
            </div>

            <footer className={styles.footer}>
              <p className={styles.footerText}>
                Copyright © 2020 Flex IP Admin. Todos os direitos reservados.
              </p>
            </footer>
          </div>
        </div>

        <Modal
          isActive={this.state.isAlertOn}
          toggleAlert={this.toggleAlert}
          title={this.state.alertMessage.title}
          text={this.state.alertMessage.message}
        />
      </>
    );
  }
}
