import React, { useState, useEffect } from "react";

import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../../store/actions/user";

import api from "../../services/api";
import { API_GUARD } from "../../services/env";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import NotFound from "../Errors/NotFound";

import Dashboard from "../../pages/User/Dashboard";
import SystemSettings from "../../pages/User/Admin/SystemSettings";
import Users from "../../pages/User/Admin/Users";

import Adverts from "../../pages/User/PABX/Adverts";
import AudioManagement from "../../pages/User/PABX/AudioManagement";
import AutoAnswer from "../../pages/User/PABX/AutoAnswer";
import Branches from "../../pages/User/PABX/Branches";
import CreateBranch from "../../pages/User/PABX/Branches/CreateBranch";
import EditBranch from "../../pages/User/PABX/Branches/EditBranch";
import CallQueues from "../../pages/User/PABX/CallQueues";
import Departments from "../../pages/User/PABX/Departments";
import InputRoutes from "../../pages/User/PABX/InputRoutes";
import OutputRoutes from "../../pages/User/PABX/OutputRoutes";
import RoutesGroup from "../../pages/User/PABX/RoutesGroup";
import ScheduleConditions from "../../pages/User/PABX/ScheduleConditions";
import StandbyAudio from "../../pages/User/PABX/StandbyAudio";
import Trunkes from "../../pages/User/PABX/Trunkes";

import BranchesStatus from "../../pages/User/Reception/BranchesStatus";
import OnlineCalls from "../../pages/User/Reception/OnlineCalls";
import QueueDetails from "../../pages/User/Reception/QueueDetails";

import CallReporting from "../../pages/User/Reports/CallReporting";
import MinuteChart from "../../pages/User/Reports/MinuteChart";

import Status from "../../pages/User/Status";

import MyExtension from "../../pages/User/MyExtension"

import styles from "./style.module.css";

import Footer from "../../components/Footer"

function User({ history, updateUser }) {
  // const [authenticated, setAuthenticated] = useState(true);
  const [routes, setRoutes] = useState([]);
  const [click, setClick] = useState(false);

  useEffect(() => {
    checkIfTokenExists();
    setRoutes([
      {
        path: "/admin/dashboard",
        component: Dashboard,
        exact: false
      },
      {
        path: "/admin/home",
        component: Dashboard,
        exact: false
      },
      {
        path: "/admin/system-settings",
        component: SystemSettings,
        exact: false
      },
      {
        path: "/admin/users",
        component: Users,
        exact: false
      },
      {
        path: "/admin/PABX/anuncios",
        component: Adverts,
        exact: false
      },
      {
        path: "/admin/PABX/gerenciamento-de-audio",
        component: AudioManagement,
        exact: false
      },
      {
        path: "/admin/PABX/ura-de-atendimento",
        component: AutoAnswer,
        exact: false
      },
      {
        path: "/admin/PABX/ramais",
        component: Branches,
        exact: true
      },
      {
        path: "/admin/PABX/ramais/adicionar",
        component: CreateBranch,
        exact: true
      },
      {
        path: "/admin/PABX/ramais/:id/editar",
        component: EditBranch,
        exact: true
      },
      {
        path: "/admin/PABX/filas-de-atendimento",
        component: CallQueues,
        exact: false
      },
      {
        path: "/admin/PABX/departamentos",
        component: Departments,
        exact: false
      },
      {
        path: "/admin/PABX/rotas-de-entrada",
        component: InputRoutes,
        exact: false
      },
      {
        path: "/admin/PABX/rotas-de-saida",
        component: OutputRoutes,
        exact: false
      },
      {
        path: "/admin/PABX/grupos-de-rotas",
        component: RoutesGroup,
        exact: false
      },
      {
        path: "/admin/PABX/condicoes-de-horarios",
        component: ScheduleConditions,
        exact: false
      },
      {
        path: "/admin/PABX/audio-de-espera",
        component: StandbyAudio,
        exact: false
      },
      {
        path: "/admin/PABX/troncos",
        component: Trunkes,
        exact: false
      },
      {
        path: "/admin/recepcao/status-de-ramais",
        component: BranchesStatus,
        exact: false
      },
      {
        path: "/admin/recepcao/chamadas-online",
        component: OnlineCalls,
        exact: false
      },
      {
        path: "/admin/recepcao/detalhes-fila",
        component: QueueDetails,
        exact: false
      },
      {
        path: "/admin/relatorios/relatorio-chamadas",
        component: CallReporting,
        exact: false
      },
      {
        path: "/admin/relatorios/grafico-minutos",
        component: MinuteChart,
        exact: false
      },
      {
        path: "/admin/status",
        component: Status,
        exact: false
      },
      {
        path: "/admin/meu-ramal",
        component: MyExtension,
        exact: false
      },
      {
        component: NotFound
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkIfTokenExists() {
    const access_token = await localStorage.getItem("access_token");

    if (access_token) {
      // setAuthenticated(true);
      try {
        const response = await api.post(`/v1/${API_GUARD}/auth/me`, null, {
          headers: { Authorization: "Bearer " + access_token }
        });

        if (response.status === 200) {
          const { user } = response.data;
          // setAuthenticated(true);
          updateUser(user.name, user.email);
          return;
        } else {
          // setAuthenticated(false);
          history.push("/");
          return;
        }
      } catch (error) {
        localStorage.removeItem("access_token");
        // setAuthenticated(false);
        history.push("/");
      }
    } else {
      // setAuthenticated(false);
      history.push("/");
    }
  }

  return (
    <>
      <div className={styles.container}>
        <Navbar history={history} className={styles.navbar} listenerClick={click} />
        <Sidebar history={history} className={styles.sidebar} onClick={() => setClick(!click)} />
        <div className={styles.switch} onClick={() => setClick(!click)}>
          <Switch>
            {routes.map((route, i) => (
              <Route key={i} {...route} />
            ))}
          </Switch>
        </div>
        <Footer/>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
