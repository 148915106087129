import React, { Component } from "react";

import styles from "./style.module.css";

import Logo from "../../../assets/images/login/logo-white.svg";

import api from "../../../services/api";

import { API_GUARD } from "../../../services/env";

export default class EmailVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeSuccess: false,
      activeError: false,
      message: "Estamos verificando sua conta..."
    };
    this.verifyCode = this.verifyCode.bind(this);
  }

  componentDidMount() {
    this.verifyCode();
  }

  openLogin(history) {
    history.push("/");
  }

  openRegister(history) {
    history.push("/auth/cadastrar");
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  async verifyCode() {
    try {
      this.toggleIsLoad(true);
      const { verification_code } = this.props.match.params;
      const response = await api.get(
        `/v1/${API_GUARD}/auth/verify/${verification_code}`
      );
      if (response.status && response.status === 200) {
        const { message } = response.data;
        this.setState({ message });
      }
      this.toggleIsLoad(false);
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            console.warn("Ops... algo de errado aconteceu!");
        }
      }
    }
  }

  render() {
    const { history } = this.props;
    return (
      <>
        <div className={styles.login}>
          <div className={styles.container}>
            <div className={styles.header}></div>
            <div className={styles.body}>
              <div className={styles.card}>
                <div className={styles.cardLeftSide}>
                  <img src={Logo} alt="Flex IP" className={styles.logo} />
                  <h1 className={styles.title}>PABX Flexible Admin.</h1>
                  <ul className={styles.list}>
                    <li className={styles.listItem}>
                      <i className={"icon-magic-wand " + styles.icon}></i>Custo
                      ZERO entre Filiais
                    </li>
                    <li className={styles.listItem}>
                      <i className={"icon-layers " + styles.icon}></i>Relatórios
                      Detalhados
                    </li>
                    <li className={styles.listItem}>
                      <i
                        className={"icon-arrow-right-circle " + styles.icon}
                      ></i>
                      Fácil expansão
                    </li>
                    <li className={styles.listItem}>
                      <i className={"icon-drop " + styles.icon}></i>
                      Flexibilidade
                    </li>
                  </ul>
                </div>
                <div className={styles.mobileHeader}>
                  <img src={Logo} alt="Flex IP" className={styles.logo} />
                </div>
                <div className={styles.cardRightSide}>
                  <h2 className={styles.description}>
                    <strong>ATIVE</strong> SUA CONTA
                  </h2>
                  <p className={styles.p}>{this.state.message}</p>
                </div>
              </div>
              <div className={styles.radius}>
                <div
                  className={styles.radiusLeft}
                  onClick={() => {
                    this.openRegister(history);
                  }}
                >
                  <p className={styles.radiusItem}>Cadastrar sua empresa</p>
                </div>
                <div
                  className={styles.radiusRight}
                  onClick={() => {
                    this.openLogin(history);
                  }}
                >
                  <p className={styles.radiusItem}>Fazer login</p>
                </div>
              </div>
            </div>
            <footer className={styles.footer}>
              <p className={styles.footerText}>
                Copyright © 2020 Flex IP Admin. Todos os direitos reservados.
              </p>
            </footer>
          </div>
        </div>
      </>
    );
  }
}
