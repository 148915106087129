import React, { Component } from 'react';

import Logo from '../../assets/images/logo/flexip.svg';
import LogoIcon from '../../assets/images/logo/icon.svg';

import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faPhoneAlt,
  // faWrench, faPhoneAlt, faDirections, faChartBar, faChartLine 
} from '@fortawesome/free-solid-svg-icons';

import './styles.css';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
      logoClasses: "logo display-block",
      logoIconClasses: "logoIcon display-block",
      expanded: true,
    }
  }

  toggle = (boolean) => {
    const items = document.querySelector(".sidenav---sidenav-nav---3tvij");
    console.log(items.classList);
    this.setState({ toggled: boolean })
    this.setState({expanded:boolean})
    console.log(boolean);
    if (boolean) {
      this.setState({
        logoClasses: "logo display-block",
        logoIconClasses: "logoIcon display-block"
      });
      setTimeout(() => {
        items.classList.add("display-list-items");
      }, 100);
    } else {
      this.setState({
        logoClasses: "logo display-none",
        logoIconClasses: "logoIcon display-none"
      });
      items.classList.remove("display-list-items");
    }
  }
  render() {
    const { className } = this.props;
    return (
      <>
        <SideNav
          className={className}
          onSelect={(selected) => {
            console.log(selected);
            const { history } = this.props;
            history.push(selected);
          }}
          onToggle={(boolean) => { this.toggle(boolean) }}
          expanded={this.state.expanded}
        >
          <Toggle />
          <div className={"logo-container"}>
            <img src={LogoIcon}
              alt="PABX"
              className={this.state.logoIconClasses}
            />
            <img src={Logo}
              alt="Flexip"
              className={this.state.logoClasses}
            />
          </div>

          <Nav defaultSelected="/admin/dashboard">
            <NavItem eventKey="/admin/dashboard">
              <NavIcon>
                <FontAwesomeIcon icon={faHome} style={styles.icon} />
              </NavIcon>
              <NavText>
                Home
              </NavText>
            </NavItem>
            <NavItem eventKey="/admin/meu-ramal">
              <NavIcon>
                <FontAwesomeIcon icon={faPhoneAlt} style={styles.icon} />
              </NavIcon>
              <NavText>
                Meu Ramal
              </NavText>
            </NavItem>
            {/* <NavItem eventKey="/admin">
              <NavIcon>
                <FontAwesomeIcon icon={faWrench} style={styles.icon} />
              </NavIcon>
              <NavText>
                Administração
              </NavText>
              <NavItem disabled={true} eventKey="/admin/users">
                <NavText>
                  Usuários
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/system-settings">
                <NavText>
                  Configurações Sistema
                </NavText>
              </NavItem>
            </NavItem>
            <NavItem eventKey="/admin/PABX">
              <NavIcon>
                <FontAwesomeIcon icon={faPhoneAlt} style={styles.icon} />
              </NavIcon>
              <NavText>
                PABX
              </NavText>
              <NavItem eventKey="/admin/PABX/ramais">
                <NavText>
                  Ramais
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/departamentos">
                <NavText>
                  Departamentos
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/troncos">
                <NavText>
                  Troncos
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/grupos-de-rotas">
                <NavText>
                  Grupo de Rotas
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/rotas-de-entrada">
                <NavText>
                  Rotas de Entrada
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/rotas-de-saida">
                <NavText>
                  Rotas de Saída
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/filas-de-atendimento">
                <NavText>
                  Filas de Atendimento
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/ura-de-atendimento">
                <NavText>
                  URA de Atendimento
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/condicoes-de-horarios">
                <NavText>
                  Condições de Horários
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/anuncios">
                <NavText>
                  Anúncios
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/gerenciamento-de-audio">
                <NavText>
                  Gerenciamento de Áudio
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/PABX/audio-de-espera">
                <NavText>
                  Áudio de Espera
                </NavText>
              </NavItem>
            </NavItem>
            <NavItem eventKey="/admin/recepcao">
              <NavIcon>
                <FontAwesomeIcon icon={faDirections} style={styles.icon} />
              </NavIcon>
              <NavText>
                Recepção
              </NavText>
              <NavItem disabled={true} eventKey="/admin/recepcao/status-de-ramais">
                <NavText>
                  Status de Ramais
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/recepcao/chamadas-online">
                <NavText>
                  Chamadas Online
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/recepcao/detalhes-fila">
                <NavText>
                  Detalhes Fila
                </NavText>
              </NavItem>
            </NavItem>
            <NavItem eventKey="/admin/relatorios/">
              <NavIcon>
                <FontAwesomeIcon icon={faChartBar} style={styles.icon} />
              </NavIcon>
              <NavText>
                Relatórios
              </NavText>
              <NavItem disabled={true} eventKey="/admin/relatorios/relatorio-chamadas">
                <NavText>
                  Relatório Chamadas
                </NavText>
              </NavItem>
              <NavItem disabled={true} eventKey="/admin/relatorios/grafico-minutos">
                <NavText>
                  Gráfico Minutos
                </NavText>
              </NavItem>
            </NavItem>*/}
            {/* <NavItem disabled={true} eventKey="/admin/status">
              <NavIcon>
                <FontAwesomeIcon icon={faChartLine} style={styles.icon} />
              </NavIcon>
              <NavText>
                Status
              </NavText>
            </NavItem> */}
          </Nav>
        </SideNav>
      </>
    );
  }
}

const styles = {
  icon: { fontSize: '1.75em', color: 'white' },
};