import React, { Component } from 'react';

import './styles.css';

export default class Users extends Component {
  render() {
    return (
      <div className="container">
        <h1>Users</h1>
      </div>
    );
  }
}
