import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';


// import { Container } from './styles';

export default class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelected: []
    }
  }
  componentDidMount() {
    const { defaultSelected } = this.props;
    this.setState({ defaultSelected });
  }

  componentDidUpdate(prevProps) {
    const { cleanMultiSelect } = this.props;
    if (prevProps.cleanMultiSelect !== cleanMultiSelect) {
      this._typeahead.clear();
    }
    const { defaultSelected } = this.props;
    if (prevProps.defaultSelected !== defaultSelected) {
      this.setState({ defaultSelected });
    }
  }

  render() {
    const { multiple, label, options, placeholder, onChange, isInvalid, isLoading } = this.props;
    const { defaultSelected } = this.state;
    const selected = defaultSelected ?
      defaultSelected.length > 0 ?
        !multiple ?
          options.filter(object => object.id === defaultSelected[0].id || object.name === defaultSelected[0].name)
          : options.filter(object => defaultSelected.some(item => item.id === object.id))
        : [] : [];

    const id = label;
    return (
      <React.Fragment>
        <Typeahead
          selectHintOnEnter={true}
          isLoading={isLoading}
          className={isInvalid && "is-invalid"}
          // defaultSelected={selected}
          selected={selected}
          isInvalid={isInvalid}
          id={id}
          labelKey={label}
          multiple={multiple}
          options={options}
          placeholder={placeholder}
          onChange={(event) => { onChange(event); console.log(event) }}
          ref={(ref) => this._typeahead = ref}
        />
      </React.Fragment>
    );
  }
}
