import React, { Component } from 'react';

import styles from './style.module.css';

export default class Card extends Component {
  render() {
    const { content, title, description } = this.props;
    return (
      <>
        <div className={`${styles.container} ${styles.shadowBox}`}>
          <div className={styles.header}>
            <h2 className={styles.title}>
              {title} <br />
              <small className={styles.discription}>
                {description}
              </small>
            </h2>

          </div>
          <div className={styles.body}>
            {content}
          </div>
        </div>
      </>
    );
  }
}
