import React, { Component } from 'react';

import styles from './style.module.css';
import logo from '../../../assets/images/logo/flexip.svg';
export default class NotFound extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={`${styles.header} ${styles.contentCenter}`}>
          <img src={logo} alt="Flexip" className={styles.logo} />
        </div>
        <div className={`${styles.body} ${styles.contentCenter}`}>
          <h1>404 | Página não encontrada</h1>
        </div>
        <footer className={`${styles.footer} ${styles.contentCenter}`}>
          Copyright © 2020 Flex IP Admin. Todos os direitos reservados.
        </footer>
      </div>
    );
  }
}
