import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../../../store/actions/user";

import styles from "./style.module.css";
import Logo from "../../../assets/images/login/logo-white.svg";

import api from "../../../services/api";

import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import ButtonLoading from "../../../components/Buttons/ButtonLoading";

import ForgotModal from "./ForgotModal";

import { API_GUARD } from "../../../services/env";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      register: false,
      name: "",
      password: "",
      isLoading: false,
      isAlertOn: false,
      forgotModal: false,
      validate: {
        name: {
          message: "",
          isInvalid: false
        },
        password: {
          message: "",
          isInvalid: false
        },
      },
      alertMessage: {
        title: "",
        message: ""
      }
    };
    this.checkIfTokenExists = this.checkIfTokenExists.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.error = this.error.bind(this);
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  componentDidMount() {
    this.checkIfTokenExists();
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleForgotModal() {
    this.setState({ forgotModal: !this.state.forgotModal });
  }

  handlerForgotLink(event) {
    event.preventDefault();
    this.toggleForgotModal();
  }

  async checkIfTokenExists() {
    // const { history, updateUser } = this.props;
    // const access_token = await localStorage.getItem("access_token");
    // if (access_token) {
    //   try {
    //     const response = await api.post('/v1/admin/auth/me', null, {
    //       headers: { Authorization: "Bearer " + access_token }
    //     });
    //     if (response.status === 200) {
    //       const { name, email } = response.data;
    //       updateUser(name, email);
    //       history.push("/admin/dashboard");
    //     }
    //   }
    //   catch (error) {
    //     localStorage.removeItem("access_token");
    //   }
    // }
  }

  handlerForgotModal(event) {
    event.preventDefault();
    this.forgotModal();
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async handlerLogin(event) {
    event.preventDefault();
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      const { history, updateUser } = this.props;
      const { name, password } = this.state;
      try {
        const response = await api.post(`/v1/${API_GUARD}/auth/login`, { name, password });
        if (response.status && response.status === 200) {
          const { access_token } = response.data.token;
          const { user } = response.data;
          updateUser(user.name, user.email);
          localStorage.setItem("access_token", access_token);
          history.push("/admin/dashboard");
        }
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              const { message, title } = error.response.data;
              const alertMessage = {
                title,
                message
              };
              this.activeAlert(alertMessage);
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  openLogin(history) {
    history.push("/");
  }

  openRegister(history) {
    history.push("/auth/cadastrar");
  }

  render() {
    const { history } = this.props;
    return (
      <>
        <div className={styles.login}>
          <div className={styles.container}>
            <div className={styles.header}></div>
            <div className={styles.body}>
              <div className={styles.card}>
                <div className={styles.cardLeftSide}>
                  <img src={Logo} alt="Flex IP" className={styles.logo} />
                  <h1 className={styles.title}>PABX Flexible Admin.</h1>
                  <ul className={styles.list}>
                    <li className={styles.listItem}>
                      <i className={"icon-magic-wand " + styles.icon}></i>Custo
                      ZERO entre Filiais
                    </li>
                    <li className={styles.listItem}>
                      <i className={"icon-layers " + styles.icon}></i>Relatórios
                      Detalhados
                    </li>
                    <li className={styles.listItem}>
                      <i
                        className={"icon-arrow-right-circle " + styles.icon}
                      ></i>
                      Fácil expansão
                    </li>
                    <li className={styles.listItem}>
                      <i className={"icon-drop " + styles.icon}></i>
                      Flexibilidade
                    </li>
                  </ul>
                </div>
                <div className={styles.mobileHeader}>
                  <img src={Logo} alt="Flex IP" className={styles.logo} />
                </div>
                <div className={styles.cardRightSide}>
                  <h2 className={styles.description}>
                    <strong>ACESSE</strong> SUA CONTA
                  </h2>
                  <form
                    onSubmit={event => this.handlerLogin(event)}
                    className={styles.form}
                  >
                    <Input
                      type="text"
                      name="name"
                      placeholder="Usuário"
                      icon="icon-user"
                      isInvalid={this.state.validate.name.isInvalid}
                      invalidMessage={this.state.validate.name.message}
                      onChange={(event) => this.handlerChangeInput({ name: event.target.value })}
                    />
                    <Input
                      type="password"
                      name="password"
                      placeholder="Senha"
                      icon="icon-lock"
                      isInvalid={this.state.validate.password.isInvalid}
                      invalidMessage={this.state.validate.password.message}
                      onChange={event =>
                        this.handlerChangeInput({
                          password: event.target.value
                        })
                      }
                    />
                    <a
                      href="/"
                      className={styles.link}
                      onClick={event => {
                        this.handlerForgotLink(event);
                      }}
                    >
                      Esqueci minha senha
                    </a>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Entrar"
                    />
                  </form>
                </div>
              </div>
              <div className={styles.radius}>
                <div
                  className={styles.radiusLeft}
                  onClick={() => {
                    this.openRegister(history);
                  }}
                >
                  <p className={styles.radiusItem}>Cadastrar sua empresa</p>
                </div>
                <div
                  className={styles.radiusRight}
                  onClick={() => {
                    this.openLogin(history);
                  }}
                >
                  <p className={styles.radiusItem}>Fazer login</p>
                </div>
              </div>
            </div>

            <footer className={styles.footer}>
              <p className={styles.footerText}>
                Copyright © 2020 Flex IP Admin. Todos os direitos reservados.
              </p>
            </footer>
          </div>
        </div>

        <Modal
          isActive={this.state.isAlertOn}
          toggleAlert={this.toggleAlert}
          title={this.state.alertMessage.title}
          text={this.state.alertMessage.message}
        />
        <ForgotModal
          isActive={this.state.forgotModal}
          toggleForgotModal={() => this.toggleForgotModal()}
          feedback={alertMessage => this.activeAlert(alertMessage)}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions, dispatch);

export default connect(null, mapDispatchToProps)(Login);
