import React, { Component } from 'react';

import Chart from 'chart.js';

import styles from './styles.module.css';

import api from '../../../services/api';
import { API_GUARD } from '../../../services/env';

import Card from '../../../components/Card';
import Header from '../../../components/Header';
import Modal from '../../../components/Modal';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // states here
      isAlertOn: false,
      pabxList: []
    }
    this.customerSatisfactionChart = this.customerSatisfactionChart.bind(this);
    this.percentagePlanChart = this.percentagePlanChart.bind(this);
    this.dailyCallsChart = this.dailyCallsChart.bind(this);
    this.spensesChart = this.spensesChart.bind(this);
    this.profileChart = this.profileChart.bind(this);
    this.callTypesFrequence = this.callTypesFrequence.bind(this);
    this.getPABXList = this.getPABXList.bind(this);
  }

  componentDidMount() {
    // this.getPABXList();
    this.percentagePlanChart();
    this.dailyCallsChart();
    this.customerSatisfactionChart();
    this.spensesChart();
    this.profileChart();
    this.callTypesFrequence();
  }

  async getPABXList() {
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/pabx`,
      {
        headers: { Authorization: "Bearer " + access_token }
      });

    if (response.status === 200) {
      const { pabxList } = response.data;

      this.setState({ pabxList });
    }
  }

  toggleAlert() {
    this.setState({ isAlertOn: !this.state.isAlertOn });
  }

  callTypesFrequence() {
    const ctx = document.getElementById('call-types');
    const data = {
      datasets: [{
        data: [10, 20, 30],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
      }],
      labels: [
        'Red',
        'Yellow',
        'Blue'
      ],

    };
    const options = {};
    const chart = new Chart(ctx, {
      data: data,
      type: 'polarArea',
      options: options
    });
    return chart;
  }

  profileChart() {
    const ctx = document.getElementById('profiles');
    const data = {
      labels: ['Running', 'Swimming', 'Eating', 'Cycling'],
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: "rgba(179,181,198,0.2)",
          borderColor: "rgba(179,181,198,1)",
          pointBackgroundColor: "rgba(179,181,198,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(179,181,198,1)",
          data: [65, 59, 90, 81, 56, 55, 40]
        },
        {
          label: "My Second dataset",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          pointBackgroundColor: "rgba(255,99,132,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(255,99,132,1)",
          data: [28, 48, 40, 19, 96, 27, 100]
        }
      ],
    }
    const options = {
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.yLabel;
          }
        }
      },
      scale: {
        angleLines: {
          display: false
        },
        ticks: {
          suggestedMin: 50,
          suggestedMax: 100
        }
      }
    };
    const chart = new Chart(ctx, {
      type: 'radar',
      data: data,
      options: options
    });

    return chart;
  }

  spensesChart() {
    const ctx = document.getElementById('spenses');
    const data = {
      labels: ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00'],
      datasets: [{
        label: '# of calls',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1
      }]
    };
    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          stacked: true
        }]
      }
    };
    const chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: options
    });
    return chart;
  }

  customerSatisfactionChart() {
    //customer-satisfaction
    const ctx = document.getElementById('customer-satisfaction');
    const data = {
      datasets: [{
        data: [10, 20, 30],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
      }],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        'Red',
        'Yellow',
        'Blue'
      ]
    };
    const options = {};
    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: options
    });
    return chart;
  }

  percentagePlanChart() {
    const ctx = document.getElementById('percentage-plan');
    const data = {
      datasets: [{
        data: [10, 20, 30],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
      }],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        'Red',
        'Yellow',
        'Blue'
      ]
    };
    const options = {};
    const chart = new Chart(ctx, {
      data: data,
      type: 'pie',
      options: options
    });
    return chart;
  }

  dailyCallsChart() {
    const ctx = document.getElementById('daily-calls-chart');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00'],
        datasets: [{
          label: '# of calls',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    return chart;
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <Header title="Dashboard" description="Bem-vindo!" />
          <div className={styles.itemsHorizontal}>
            <Card
              title="Ligações Diárias"
              description="Dados das ligações diárias"
              content={(
                <canvas id="daily-calls-chart" responsive="true"></canvas>
              )}
            />
            <Card
              title="Perfil do Usuário"
              description="Dados com perfil do usuário."
              content={(
                <canvas id="profiles" responsive="true"></canvas>
              )}
            />
            <Card
              title="Frequência de Chamadas"
              description="Dados com frequência dos tipos de chamadas."
              content={(
                <canvas id="call-types" responsive="true"></canvas>
              )}
            />
            <Card
              title="Gastos"
              description="Dados dos gastos relacionados aos ramais."
              content={(
                <canvas id="spenses" responsive="true"></canvas>
              )}
            />
            <Card
              title="Porcentagem do Plano"
              description="Dados da utilização do plano."
              content={(
                <canvas id="percentage-plan" responsive="true" ></canvas>
              )}
            />
            <Card
              title="Satisfação do cliente"
              description="Dados com avaliações dos clientes."
              content={(
                <canvas id="customer-satisfaction" responsive="true"></canvas>
              )}
            />
          </div>
          <Modal
            isActive={this.state.isAlertOn}
            title={"Nova Mensagem!"}
            content={(
              <p>
                A fatura referente ao mês de dezembro ainda
                está em aberta, favor efetuar o pagamento e
                      enviar comprovante no e-mail:<br /><br />
                <a href="mailto:suporte@wfgsoluctions.com.br" className="link">
                  suporte@wfgsoluctions.com.br
                      </a>.
                    </p>
            )} toggleAlert={() => this.toggleAlert()} />
        </div>
      </>
    );
  }
}