import React, { Component } from 'react';

import './styles.css';

export default class RoutesGroup extends Component {
  render() {
    return (
      <div className="container">
        <h1>Routes Group</h1>
      </div>
    );
  }
}
