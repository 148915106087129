import React, { Component } from 'react';

import styles from './style.module.css';

export default class Header extends Component {
  render() {
    const { title, description } = this.props;
    return (
      <>
        <header className={`${styles.header} ${styles.shadowBox}`}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              {title} <br />
              <small className={styles.description}>{description}</small>
            </h1>

          </div>
        </header>
      </>
    );
  }
}
