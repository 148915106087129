import React, { Component } from 'react';

import styles from './styles.module.css';

import Button from '../../../../components/Buttons/Button';
import Modal from '../../../../components/Modal';
import Header from '../../../../components/Header';

import api from '../../../../services/api';
import { API_GUARD } from '../../../../services/env';

export default class Branches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      branches: [],
      isAlertOn: false,
      alertMessage: {
        title: "",
        message: ""
      }
    }
    this.getBranches = this.getBranches.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  componentDidMount() {
    this.getBranches();
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn,
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  async getBranches() {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.get(`/v1/${API_GUARD}/branch`, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        const { branches } = response.data;
        this.setState({ branches });
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.setState({ isLoading: false });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerHeaderCreateBranch() {
    const { history } = this.props;

    history.push("/admin/PABX/ramais/adicionar");
  }

  handlerHeaderEditBranch(id) {
    const { history } = this.props;

    history.push(`/admin/PABX/ramais/${id}/editar`);
  }

  async handlerDestroyBranch(id) {
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.delete(`/v1/${API_GUARD}/branch/${id}`, {
          headers: { Authorization: "Bearer " + access_token }
        });
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.getBranches()
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const { isLoading, branches } = this.state;
    return (
      <div className={`${styles.container}`}>
        <Header title="Ramais" description="Gerenciamento de Ramais" />
        <div className={`${styles.body} ${styles.shadowBox}`}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <h2>Listagem de Ramais</h2>
            </div>
            <div className={styles.tableOption}>
              <Button text="Adicionar"
                type="button"
                onClick={() => { this.handlerHeaderCreateBranch() }}
              />
            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>#</th>
                <th>Número</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <tr>
                    <th colSpan="4">Carregando listagem...</th>
                  </tr>
                ) :
                  !(branches.length > 0) ? (
                    <tr>
                      <th colSpan="4">Nenhum registro encontrado.</th>
                    </tr>
                  ) :
                    branches.map((branch, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{branch.number}</td>
                        <td>
                          <div className={styles.tableButton}>
                            <Button text="Editar"
                              type="button"
                              onClick={() => { this.handlerHeaderEditBranch(branch.id) }}
                            />
                            <Button text="Excluir"
                              type="button"
                              onClick={() => { this.handlerDestroyBranch(branch.id) }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
              }
            </tbody>
          </table>
          <Modal
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
        </div>
      </div>
    );
  }
}
