import React, { Component } from 'react';
import styles from './style.module.css';


export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
         
        }
    }
    render(){
       
       return(
        <footer>
            <div className={styles.version}>
                <span className={styles.textVersion}>Versão 2.7</span>
            </div>
        </footer>

       )
    }
}