import React, { Component } from 'react';
import api from '../../../services/api';
import { API_GUARD } from '../../../services/env';
import Header from '../../../components/Header';
import styles from './styles.module.css';

export default class MyExtension extends Component {
    constructor(props){
        super(props);
        this.state = {
            imgQr: "",
            domain: "",
            user:"",
            isLoading:true
        }
  
    }  

  componentDidMount(){
        console.log('opa')
        this.getQrCode()

  }  
  async getQrCode(){
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/data/qr_code`,
      {
        headers: { Authorization: "Bearer " + access_token }
      });

    if (response.status === 200) {
      
        console.log(response.data)

        this.setState({
            imgQr: response.data.image,
            domain: response.data.domain,
            user: response.data.user,
            isLoading:false
        })
      
    }
  }


  render() {
     const {imgQr, domain, user, isLoading} = this.state 
    return (
      <div>
        
          <Header title="Meu Ramal" description="" />

        <div className={styles.content}>  

          <div className={styles.text}>
              <h2><span>Domínio:</span> {domain}</h2>
              <h3><span>Usuário:</span> {user}</h3 >
          </div>

          <div className={styles.contentImg}>
             {!isLoading ?    
                <img className={styles.imgQr} src={imgQr} alt="qr code"/>
                
                : <div className={styles.text}> <h3>Carregando...</h3> </div>
             }
          </div>  

          </div>

      </div>
    );
  }
}