import React, { Component } from "react";

import styles from "./styles.module.css";

import Header from "../../../../../components/Header";
import Modal from "../../../../../components/Modal";
import ButtonLoading from "../../../../../components/Buttons/ButtonLoading";
import { Form, Col, Row, Accordion, Card } from "react-bootstrap";
import MaskedFormControl from 'react-bootstrap-maskedinput'

import MultiSelect from "../../../../../components/Inputs/Bootstrap/MultiSelect";

import api from "../../../../../services/api";
import { API_GUARD } from "../../../../../services/env";

import { branch } from "../../../../../services/BranchService";

export default class CreateBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...branch,
      isLoadingForm: true,
      advancedToggled: false,
      cleanMultiSelect: false,

      pabxList: [],
      callGroups: [],
      codecs: [],
      pickupGroups: [],
      routeGroups: [],

      isLoading: false,
      isAlertOn: false,
      forgotModal: false,
      alertMessage: {
        title: "",
        message: ""
      }
    };
    this.name = React.createRef();
    this.qualifyFalse = React.createRef();
    this.qualifyTrue = React.createRef();
    this.toggleIsLoadForm = this.toggleIsLoadForm.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.getCreateBranch = this.getCreateBranch.bind(this);
  }

  toggleCleanMultiSelect() {
    this.setState({ cleanMultiSelect: !this.state.cleanMultiSelect });
  }

  setDefaultQualify() {
    if (this.state.qualify) {
      this.qualifyTrue.current.checked = "checked";
    } else {
      this.qualifyFalse.current.checked = "checked";
    }
  }

  clearForm() {
    this.setDefaultQualify();
    this.toggleCleanMultiSelect();
    this.setState(prevState => {
      return {
        ...prevState,
        ...branch,
      };
    });
    this.name.current.focus();
  }

  componentDidMount() {
    this.setDefaultQualify();
    this.name.current.focus();
    this.getCreateBranch();
  }

  async getCreateBranch() {
    this.toggleIsLoadForm(true);
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/branch/create`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { pabxList, callGroups, codecs, pickupGroups, routeGroups } = response.data;

      this.setState({ pabxList, callGroups, codecs, pickupGroups, routeGroups });
    }
    this.toggleIsLoadForm(false);
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn
    });
  }


  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  toggleIsLoadForm(boolean) {
    this.setState({ isLoadingForm: boolean });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async handlerStore(event) {
    event.preventDefault();
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const {
        callGroupId,
        codecId,
        pickupGroupId,
        number,
        nat,
        port,
        callLimit,
        routeGroupId,
        dtmf,
        qualify,
        name,
        email,
        password,
        passwordConfirm,
        externalNumber,
        pabxId
      } = this.state;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.post(
          `/v1/${API_GUARD}/branch`,
          {
            callGroupId,
            codecId,
            pickupGroupId,
            number,
            nat,
            port,
            callLimit,
            routeGroupId,
            dtmf,
            qualify,
            name,
            email,
            password,
            passwordConfirm,
            externalNumber,
            pabxId
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.clearForm();
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
            // 
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const {
      cleanMultiSelect,
      isLoadingForm,
      pabxId,
      callGroupId,
      codecId,
      pickupGroupId,
      number,
      nat,
      port,
      callLimit,
      routeGroupId,
      dtmf,
      name,
      email,
      password,
      passwordConfirm,
      externalNumber,
      callGroups,
      codecs,
      pickupGroups,
      routeGroups,
      validate,
      advancedToggled
    } = this.state;

    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 1 },
      md: { offset: 2, span: 1 },
      lg: { offset: 2, span: 1 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 }
    };

    const alignAccordingLabel = {
      xs: { span: 12 },
      sm: { span: 2 },
      md: { offset: 1, span: 2 },
      lg: { span: 2 }
    };

    const alignAccordingInput = {
      xs: { span: 12 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 }
    };
    return (
      <div className={`${styles.container}`}>
        <Header title="Ramais" description="Gerenciamento de Ramais" />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Editar Ramal</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerStore(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Nome:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.name}
                      isInvalid={validate.name.isInvalid}
                      type="text"
                      name="name"
                      value={name}
                      placeholder="Nome do responsável"
                      onChange={event =>
                        this.handlerChangeInput({ name: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.name.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalBranch">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Nº Externo:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <MaskedFormControl
                      mask={'(11) 1111-1111'}
                      placeholderChar=" "
                      className={validate.externalNumber.isInvalid && "is-invalid"}
                      type="tel"
                      name="externalNumber"
                      value={externalNumber}
                      placeholder="Número externo"
                      onChange={event => {
                        this.handlerChangeInput({
                          externalNumber: event.target.value.replace(/\D/g, "")
                        });
                        console.log(event.target.value.replace(/\D/g, ""));
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.externalNumber.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  controlId="formHorizontalConfirmPassword"
                >
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Nº Ramal:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <MaskedFormControl
                      mask={'11111'}
                      placeholderChar=" "
                      className={validate.externalNumber.isInvalid && "is-invalid"}
                      type="tel"
                      name="number"
                      min={0}
                      max={9999}
                      value={number}
                      placeholder="Número do ramal"
                      onChange={event =>
                        this.handlerChangeInput({
                          number: event.target.value
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.number.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    E-mail:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={validate.email.isInvalid}
                      type="email"
                      name="email"
                      value={email}
                      placeholder="E-mail"
                      onChange={event =>
                        this.handlerChangeInput({ email: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.email.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalPassword">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Senha:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={validate.password.isInvalid}
                      type="password"
                      name="password"
                      value={password}
                      placeholder="Senha"
                      onChange={event =>
                        this.handlerChangeInput({
                          password: event.target.value
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.password.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="password">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    {/* Confirmar Senha: */}
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={validate.password.isInvalid}
                      type="password"
                      name="passwordConfirm"
                      value={passwordConfirm}
                      placeholder="Confirmar senha"
                      onChange={event =>
                        this.handlerChangeInput({
                          passwordConfirm: event.target.value
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.password.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="nat">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    NAT:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <MultiSelect
                      cleanMultiSelect={cleanMultiSelect}
                      isLoading={isLoadingForm}
                      isInvalid={validate.nat.isInvalid}
                      multiple={false}
                      label="name"
                      placeholder="NAT"
                      options={[
                        { id: 1, name: "yes" },
                        { id: 2, name: "no" },
                        { id: 3, name: "force_report" },
                        { id: 4, name: "force_report_comedia" }
                      ]}
                      defaultSelected={[{ name: nat }]}
                      // defaultSelected={[{ name: "yes" }]}
                      onChange={event =>
                        event.length > 0
                          ? this.handlerChangeInput({ nat: event[0].name })
                          : this.handlerChangeInput({ nat: "" })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.nat.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Row>
                  <Col xs={{ span: 12 }} md={{ offset: 1, span: 10 }}>
                    <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
                      <Card style={{ padding: 1 }}>
                        <Accordion.Toggle
                          as={Card.Header}
                          style={{ cursor: "pointer", width: "100%" }}
                          eventKey="1"
                          className={advancedToggled ? styles.panelTitleToggled : styles.panelTitle}
                          onClick={() => { this.setState({ advancedToggled: !advancedToggled }) }}
                        >
                          Siga-me
                      </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="1"
                          style={{ height: "auto" }}
                        >
                          <Card.Body style={{ backgroundColor: "#fff" }}>
                            <Form.Group as={Row} controlId="ever">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Sempre:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  isInvalid={validate.pabxId.isInvalid}
                                  multiple={false}
                                  label="name"
                                  placeholder="Escolha o destino"
                                  options={[
                                    { id: 1, name: "Ramais" },
                                    { id: 2, name: "URA" },
                                    { id: 3, name: "Filas" },
                                    { id: 4, name: "Desligamento" },
                                    { id: 5, name: "Anúncio" },
                                    { id: 5, name: "Telefone Externo" },
                                  ]}
                                  selected={pabxId}
                                  onChange={event =>
                                    console.log(event)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.pabxId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="busy">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Ocupado:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  isInvalid={validate.pabxId.isInvalid}
                                  multiple={false}
                                  label="name"
                                  placeholder="Escolha o destino"
                                  options={[
                                    { id: 1, name: "Ramais" },
                                    { id: 2, name: "URA" },
                                    { id: 3, name: "Filas" },
                                    { id: 4, name: "Desligamento" },
                                    { id: 5, name: "Anúncio" },
                                    { id: 5, name: "Telefone Externo" },
                                  ]}
                                  selected={pabxId}
                                  onChange={event =>
                                    console.log(event)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.pabxId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="withoutRegister">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Sem Registro:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  isInvalid={validate.pabxId.isInvalid}
                                  multiple={false}
                                  label="name"
                                  placeholder="Escolha o destino"
                                  options={[
                                    { id: 1, name: "Ramais" },
                                    { id: 2, name: "URA" },
                                    { id: 3, name: "Filas" },
                                    { id: 4, name: "Desligamento" },
                                    { id: 5, name: "Anúncio" },
                                    { id: 5, name: "Telefone Externo" },
                                  ]}
                                  selected={pabxId}
                                  onChange={event =>
                                    console.log(event)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.pabxId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="withoutAttendance">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Sem Registro:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  isInvalid={validate.pabxId.isInvalid}
                                  multiple={false}
                                  label="name"
                                  placeholder="Escolha o destino"
                                  options={[
                                    { id: 1, name: "Ramais" },
                                    { id: 2, name: "URA" },
                                    { id: 3, name: "Filas" },
                                    { id: 4, name: "Desligamento" },
                                    { id: 5, name: "Anúncio" },
                                    { id: 5, name: "Telefone Externo" },
                                  ]}
                                  selected={pabxId}
                                  onChange={event =>
                                    console.log(event)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.pabxId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 12 }} md={{ offset: 1, span: 10 }}>
                    <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
                      <Card style={{ padding: 1 }}>
                        <Accordion.Toggle
                          as={Card.Header}
                          style={{ cursor: "pointer", width: "100%" }}
                          eventKey="1"
                          className={advancedToggled ? styles.panelTitleToggled : styles.panelTitle}
                          onClick={() => { this.setState({ advancedToggled: !advancedToggled }) }}
                        >
                          Avançado
                      </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="1"
                          style={{ height: "auto" }}
                        >
                          <Card.Body style={{ backgroundColor: "#fff" }}>
                            <Form.Group as={Row} controlId="routeGroup">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Grupo de Rotas:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  defaultSelected={[{ id: routeGroupId }]}
                                  isInvalid={validate.routeGroupId.isInvalid}
                                  multiple={false}
                                  label="name"
                                  placeholder="Selecione um grupo..."
                                  options={routeGroups}
                                  onChange={event =>
                                    event.length > 0
                                      ? this.handlerChangeInput({ routeGroupId: event[0].id })
                                      : this.handlerChangeInput({ routeGroupId: "" })
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.routeGroupId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              controlId="port"
                            >
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Porta:
                            </Form.Label>
                              <Col xs={alignAccordingInput.xs} md={alignAccordingInput.md}>
                                <Form.Control
                                  isInvalid={validate.port.isInvalid}
                                  type="number"
                                  name="port"
                                  value={port}
                                  placeholder="Digite a porta"
                                  onChange={event =>
                                    this.handlerChangeInput({
                                      port: event.target.value
                                    })
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.port.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              controlId="callLimit"
                            >
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Limite de chamada:
                            </Form.Label>
                              <Col xs={alignAccordingInput.xs} md={alignAccordingInput.md}>
                                <Form.Control
                                  isInvalid={validate.callLimit.isInvalid}
                                  type="text"
                                  name="callLimit"
                                  value={callLimit}
                                  placeholder="Digite o limite"
                                  onChange={event =>
                                    this.handlerChangeInput({
                                      callLimit: event.target.value
                                    })
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.callLimit.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="dtmf">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                DTMF:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  defaultSelected={[{ name: dtmf }]}
                                  isInvalid={validate.dtmf.isInvalid}
                                  multiple={false}
                                  label="name"
                                  placeholder="Selecione um DTMF..."
                                  options={[
                                    { id: 1, name: "rfc2833" },
                                    { id: 2, name: "info" },
                                    { id: 3, name: "inband" },
                                    { id: 4, name: "auto" },
                                  ]}
                                  selected={dtmf}
                                  onChange={event =>
                                    event.length > 0
                                      ? this.handlerChangeInput({ dtmf: event[0].name })
                                      : this.handlerChangeInput({ dtmf: "" })
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.dtmf.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="codec">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                CODEC:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  defaultSelected={codecId.map(item => { return { id: item } })}
                                  isInvalid={validate.codecId.isInvalid}
                                  multiple={true}
                                  label="name"
                                  placeholder="Selecione um ou mais CODEC..."
                                  options={codecs}
                                  selected={codecId}
                                  onChange={event =>
                                    event.length > 0
                                      ? this.handlerChangeInput({
                                        codecId: event.map((item) => { return item.id })
                                      })
                                      : this.handlerChangeInput({ codecId: [] })
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.codecId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="callGroup">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Grupo Ramal:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  defaultSelected={[{ id: callGroupId }]}
                                  isInvalid={validate.callGroupId.isInvalid}
                                  multiple={false}
                                  label="name"
                                  placeholder="Selecione um grupo..."
                                  options={callGroups}
                                  onChange={event =>
                                    event.length > 0
                                      ? this.handlerChangeInput({ callGroupId: event[0].id })
                                      : this.handlerChangeInput({ callGroupId: "" })
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.callGroupId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="pickUpGroup">
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Grupo Captura:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <MultiSelect
                                  cleanMultiSelect={cleanMultiSelect}
                                  isLoading={isLoadingForm}
                                  defaultSelected={pickupGroupId.map(item => { return { id: item } })}
                                  isInvalid={validate.pickupGroupId.isInvalid}
                                  multiple={true}
                                  label="name"
                                  placeholder="Selecione um grupo de captura..."
                                  options={pickupGroups}
                                  selected={pickupGroupId}
                                  onChange={event =>
                                    event.length > 0
                                      ? this.handlerChangeInput({
                                        pickupGroupId: event.map((item) => { return item.id })
                                      })
                                      : this.handlerChangeInput({ pickupGroupId: [] })
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.pickupGroupId.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label
                                column
                                xs={alignAccordingLabel.xs}
                                md={alignAccordingLabel.md}
                              >
                                Latência:
                            </Form.Label>
                              <Col
                                xs={alignAccordingInput.xs}
                                md={alignAccordingInput.md}
                              >
                                <Form.Check
                                  type="radio"
                                  label="Sim"
                                  isInvalid={validate.qualify.isInvalid}
                                  name="qualify"
                                  value={true}
                                  onChange={event => this.handlerChangeInput({ qualify: true })}
                                  ref={this.qualifyTrue}
                                />
                                <Form.Check
                                  type="radio"
                                  label="Não"
                                  isInvalid={validate.qualify.isInvalid}
                                  name="qualify"
                                  value={false}
                                  ref={this.qualifyFalse}
                                  onChange={event => this.handlerChangeInput({ qualify: false })}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {validate.qualify.message}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Cadastrar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={this.state.isAlertOn}
              toggleAlert={this.toggleAlert}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div>
    );
  }
}
